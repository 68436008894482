<template>
    <div>
        <add-data-modal :data="selectedTabData" :type="selectedType" @refresh="refreshTableAndLog"/>
        <modify-data-modal :data="dataToBeModified" :tab-data="auxiliaryDataForModification"
                           @refresh="refreshTableAndLog"/>
        <b-card>
            <b-tabs pills vertical>
                <b-tab
                        v-for="tabData in tabDatas"
                        :key="tabData.type_name"
                        @click="initTab(tabData.type_name)"
                        class="text-left"
                        lazy
                >
                    <template #title>
            <span class="font-weight-bold ml-0 text-left">
              {{ tabData.name.charAt(0).toUpperCase() + tabData.name.slice(1) }}
              &nbsp
              <b-badge v-if="tabData.cnt > 0" variant="success">{{ tabData.cnt }}</b-badge>
            </span>
                    </template>
                    <div>
                        <vue-good-table
                                mode="remote"
                                :columns="columns"
                                :rows="rows"
                                :pagination-options="{
                  enabled: true,
                  perPage: pageLength
                }"
                                :sort-options="{
                  enabled: true,
                  initialSortBy: sortBy
                }"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                styleClass="vgt-table"
                        >
                            <div slot="table-actions">
                                <b-link
                                        v-b-modal.form-add-meteorology-modal
                                >
                                    <b-button
                                            variant="primary"
                                    >
                                        <span class="text-nowrap">Hozzáadás</span>
                                    </b-button>
                                </b-link>
                            </div>
                            <div slot="emptystate">
                                <div class="vgt-center-align vgt-text-disabled">
                                    {{ $t('table.list.empty') }}
                                </div>
                            </div>
                            <template
                                    slot="table-row"
                                    slot-scope="props"
                            >

                                <!-- Column: Name -->
                                <span
                                        v-if="props.column.field === 'title'"
                                        class="text-nowrap"
                                >
                <span class="text-nowrap">{{ props.row.title }}</span>
              </span>

                                <span
                                        v-else-if="props.column.field === 'type'"
                                        class="text-nowrap"
                                >
                <span class="text-nowrap" v-html="props.row.type"></span>
              </span>

                                <!-- Column: Action -->
                                <span v-else-if="props.column.field === 'action'">
                <span v-if="$acl.canEditPermission(role)">
                  <b-button
                          size="sm"
                          variant="primary"
                          class="mr-1"
                          @click.stop="modifyEntity(props.row, tabData)"
                  >
                    <feather-icon
                            icon="EditIcon"
                    />
                  </b-button>
                </span>
                <span v-if="$acl.canDeletePermission(role)">
                  <b-button
                          size="sm"
                          variant="danger"
                          class=""
                          @click.stop="deleteEntity(props.row.id)"
                  >
                    <feather-icon
                            icon="TrashIcon"
                    />
                  </b-button>
                </span>
              </span>

                                <!-- Column: Common -->
                                <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
                            </template>

                            <!-- pagination -->
                            <template
                                    slot="pagination-bottom"
                                    slot-scope="props"
                            >
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ ((page - 1) * pageLength) + 1 }} +
            </span>
                                        <b-form-select
                                                v-model="pageLength"
                                                :options="pages"
                                                class="mx-1"
                                                @change="handlePageChange"
                                        />
                                        <span class="text-nowrap "> a {{ dataFromServer.total }} találatból </span>
                                    </div>
                                    <div>
                                        <b-pagination
                                                :value="1"
                                                :total-rows="dataFromServer.total"
                                                :per-page="dataFromServer.per_page"
                                                first-number
                                                last-number
                                                align="right"
                                                prev-class="prev-item"
                                                next-class="next-item"
                                                class="mt-1 mb-0"
                                                @change="handleChangePage"
                                        >
                                            <template #prev-text>
                                                <feather-icon
                                                        icon="ChevronLeftIcon"
                                                        size="18"
                                                />
                                            </template>
                                            <template #next-text>
                                                <feather-icon
                                                        icon="ChevronRightIcon"
                                                        size="18"
                                                />
                                            </template>
                                        </b-pagination>
                                    </div>
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </b-tab>
            </b-tabs>
        </b-card>
        <!-- table -->
        <!-- log -->
        <b-card>
            <tabs
                    ref="tabs"
            />
        </b-card>
    </div>
</template>

<script>
import {
    BCard, BTabs, BTab, BBadge, BButton, BLink, BFormSelect, BPagination, BModal,
    VBModal
} from 'bootstrap-vue'
import addDataModal from "@/views/pages/_components/_modal/meteorology/add.vue";
import modifyDataModal from "@/views/pages/_components/_modal/meteorology/modify.vue";
import {VueGoodTable} from 'vue-good-table'
import tabs from '@/views/pages/_components/_tabs/_meteorology/dataList.vue'

export default {
    components: {
        BCard,
        BTabs,
        BBadge,
        BTab,
        BButton,
        BLink,
        BFormSelect,
        BPagination,
        addDataModal,
        modifyDataModal,
        VueGoodTable,
        tabs,
    },
    data() {
        return {
            role: 'meteorology',
            selectedType: null,
            selectedTabData: {}, // kell a modalnak pl.
            tabDatas: [],
            dataToBeModified: {},
            auxiliaryDataForModification: {},
            // table
            pageLength: 10,
            pages: ['10', '20', '50'],
            columns: [
                {
                    label: this.$t('form.id'),
                    field: 'id',
                    sortable: false,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Azon. szerinti szűrés',
                    },
                    width: '5%',
                },
                {
                    label: 'Érték',
                    field: 'value',
                    formatFn: this.formatValue,
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Érték szerinti szűrés',
                    },
                },
                {
                    label: 'Létrehozva',
                    field: 'created_at',
                    formatFn: this.$formatters.formatLaravelTimestampToDateTime,
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Érték szerinti szűrés',
                    },
                },
            ],
            rows: [],
            page: 1,
            dataFromServer: [],
            filterData: [],
            searchTerm: '',
            sortBy: {
                field: 'created_at',
                type: 'desc',
            },
            columnFilters: {},
        }
    },
    directives: {
        'b-modal': VBModal
    },
    created() {
        let storedPageLength = localStorage.getItem('pageLength')
        if (storedPageLength) {
            this.pageLength = storedPageLength
        }


        if (this.$acl.canEditPermission(this.role) || this.$acl.canDeletePermission(this.role)) {
            const actions = [
                {
                    label: this.$t('table.header.actions'),
                    field: 'action',
                    sortable: false,
                    width: '10rem',
                },
            ]

            this.columns = [...this.columns, ...actions]
        }

        this.$store.dispatch('fetchMeteorologyTabData').then(response => {
            this.tabDatas = response.data.meta
            this.initTab(this.tabDatas[0].type_name)
        })
    },
    methods: {
        initTab(type) {
            this.setSelectedTabData(type)

            // reset table data
            this.rows = []
            this.page = 1
            this.dataFromServer = []
            this.filterData = []
            this.searchTerm = ''
            this.sortBy = {
                field: 'created_at',
                type: 'desc',
            }
            this.columnFilters = {}

            // get entities
            this.initEntities()
        },
        initEntities() {
            let filters = this.columnFilters
            filters.type = this.selectedType

            const searchParams = {
                type: this.selectedType,
                page: parseInt(this.page),
                limit: parseInt(this.pageLength),
                query: this.searchTerm,
                columnFilters: this.columnFilters,
                sort: this.sortBy,
            }

            this.$store.dispatch('fetchMeteorologyDataByType', searchParams).then(response => {
                this.rows = response.data.entities.data
                this.dataFromServer = response.data.entities
                this.page = this.dataFromServer.current_page
                this.pageLength = parseInt(this.dataFromServer.per_page)
            })
        },
        refreshTableAndLog() {
            this.refreshTable()
            this.$refs.tabs.refreshLog()
        },
        refreshTable() {
            this.initEntities()
        },
        setSelectedTabData(type) {
            this.selectedType = type
            this.tabDatas.forEach(tabData => {
                if (tabData.type_name === type) {
                    this.selectedTabData = tabData
                }
            })
        },
        formatValue(value) {
            let ret = value

            if (this.selectedTabData.hasOwnProperty('input') && ['select'].includes(this.selectedTabData.input.data_type)) {

                if (['cloud', 'nature_of_precipitation', 'ice_cover'].includes(this.selectedType)) {
                    const iValue = parseInt(value)
                    this.selectedTabData.input.available_values.forEach((aValue, index) => {
                        if (index === iValue) {
                            ret = aValue.label
                        }
                    })
                } else if (['wind_direction', 'wind_strength'].includes(this.selectedType)) {
                    let vValue = parseFloat(value)

                    if (this.selectedType === 'wind_direction') {
                        if (vValue === 0) {
                            vValue = 360
                        }
                        this.selectedTabData.input.available_values.forEach(aValue => {
                            if (vValue >= aValue.min && vValue <= (aValue.min + 45)) { // van egy olyan érték, ahol a max 22.5, nem törtem meg az adatstruktúrát
                                ret = aValue.label
                            }
                        })
                    }

                    if (this.selectedType === 'wind_strength') {
                        this.selectedTabData.input.available_values.forEach(aValue => {
                            if (vValue >= aValue.min && vValue <= aValue.max) { // van egy olyan érték, ahol a max 22.5, nem törtem meg az adatstruktúrát
                                ret = aValue.label
                            }
                        })
                    }

                }

            } else if (this.selectedTabData.input.unit && this.selectedTabData.input.unit.length) {
                ret += ' ' + this.selectedTabData.input.unit
            }

            return ret
        },
        modifyEntity(rowData, tabData) {
            this.dataToBeModified = rowData
            this.auxiliaryDataForModification = tabData

            setTimeout(() => {
                this.$bvModal.show('form-modify-meteorology-modal')
            }, 100)
        },
        deleteEntity(id) {
            this.$bvModal
                .msgBoxConfirm('Biztosan törölni szeretnéd az elemet?', {
                    title: 'Jóváhagyás',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Igen',
                    cancelTitle: 'Nem',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        // axios
                        this.$store.dispatch('deleteMeteorologyData', id).then(() => {
                            this.refreshTableAndLog()
                            this.$helpers.showSuccessToast(`Sikeres törlés (${id})`)
                        })
                    }
                })
        },
        handleChangePage(page) {
            this.page = page
            this.initEntities()
        },
        handlePageChange(active) {
            this.pageLength = active
            localStorage.setItem('pageLength', active)
            this.page = 1
            this.handleChangePage()
        },
        globalSearch(params) {
            this.page = 1
            this.query = params.searchTerm
            this.initEntities()
        },
        onSortChange(params) {
            this.page = 1
            this.sortBy = params[0]
            this.initEntities()
        },
        onColumnFilter(params) {
            this.page = 1
            this.columnFilters = params.columnFilters
            this.initEntities()
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.vgt-global-search {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
</style>
