<template>
  <b-modal
      id="form-add-meteorology-modal"
      ref="show-form-add-meteorology-modal"
      title="Adat hozzáadás"
      ok-title="Mentés"
      cancel-title="Mégsem"
      no-close-on-backdrop
      :ok-disabled="isDisabled || waiting"
      cancel-variant="outline-secondary"
      ok-variant="primary"
      @show="initModal"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <validation-observer ref="rules">
      <b-form
          ref="form"
          @submit.stop.prevent="handleSubmit"
      >
        <b-row>
          <b-col sm="12">
            <b-form-group
                label="Adat"
            >
              <validation-provider
                  v-if="isFormNumber"
                  #default="{ errors }"
                  name="data"
                  rules="required"
                  vid="data"
              >
                <b-form-input
                    v-model="formData.value"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    step="1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                  v-else-if="isFormFloat"
                  #default="{ errors }"
                  name="data"
                  rules="required"
                  vid="data"
              >
                <b-form-input
                    v-model="formData.value"
                    :state="errors.length > 0 ? false:null"
                    type="number"
                    step="0.1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                  v-else-if="isSelectInput"
                  #default="{ errors }"
                  name="data"
                  rules="required"
                  vid="data"
              >
                <v-select
                    v-model="formData.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="selectOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                  v-else
                  #default="{ errors }"
                  name="data"
                  rules="required"
                  vid="data"
              >
                <b-form-input
                    v-model="formData.value"
                    :state="errors.length > 0 ? false:null"
                    type="number"
                    step="0.01"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
                label="Dátum"
            >
              <validation-provider
                  #default="{ errors }"
                  name="date"
                  rules="required"
                  vid="date"
              >
                <flat-pickr
                    v-model="formData.date"
                    class="form-control"
                    :config="flatPickRConfig"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BModal, BFormInput, BFormGroup, BForm, BRow, BCol,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BModal,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      formData: {
        value: '',
        date: '',
      },
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      selectOptions: [],
      waiting: false,
      required,
    }
  },
  props: {
    type: {
      type: String,
      required: false,
    },
    data: {
      type: Object,
      required: false,
    },
  },
  computed: {
    isDisabled() {
      return !this.formData.value.hasOwnProperty('value') && !this.formData.value.length
    },
    isFormNumber() {
      return this.data.hasOwnProperty('input') && ['integer'].includes(this.data.input.data_type)
    },
    isFormFloat() {
      return this.data.hasOwnProperty('input') && ['float'].includes(this.data.input.data_type)
    },
    isSelectInput() {
      return this.data.hasOwnProperty('input') && ['select'].includes(this.data.input.data_type)
    },
  },
  methods: {
    initModal() {
      this.resetForm()
      this.selectOptions = []
      let selectInput = false
      this.formData.date = this.$helpers.getCurrentDate('YYYY-MM-DD HH:mm')

      if (this.isSelectInput) {
        selectInput = true
        if (this.data.type_name && ['cloud', 'nature_of_precipitation', 'ice_cover'].includes(this.data.type_name)) {
          this.data.input.available_values.forEach((value, index) => {
            if(this.data.type_name === 'ice_cover' && index === 0) {
              return
            }

            this.selectOptions.push({value: index, title: value.label})
          })
        } else if (this.data.type_name && ['wind_direction', 'wind_strength'].includes(this.data.type_name)) {
          this.data.input.available_values.forEach(value => {
            this.selectOptions.push({value: value.avg, title: value.label})
          })
        }
      }

      if (selectInput) {
        this.formData.value = this.selectOptions[0]
      } else if (this.isFormFloat || this.isFormNumber) {
        this.formData.value = 0
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.waiting = true

          let fData = this.$helpers.cloneObject(this.formData)
          fData.type = this.type

          // select-ek esetén kikell venni a value-t
          if (['select'].includes(this.data.input.data_type)) {
            fData.value = fData.value.value
          }

          this.$store.dispatch('storeMeteorologyData', fData).then(() => {
            this.$helpers.showSuccessToast()
            this.$nextTick(() => {
              this.$refs['show-form-add-meteorology-modal'].toggle('#toggle-btn')
            })
            this.$emit('refresh')
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.rules.setErrors(error.response.data.errors)
            } else if (error.response.status === 500) {
              this.$helpers.showErrorToast(error.response.data.message)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.waiting = false
          })

        }
      })
    },
    handleCancel() {
      this.$refs['show-form-add-meteorology-modal'].toggle('#toggle-btn')
    },
    resetForm() {
      this.formData.value = ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
