<template>
  <b-modal
      id="form-modify-meteorology-modal"
      ref="show-form-modify-meteorology-modal"
      title="Adat módosítás"
      ok-title="Mentés"
      cancel-title="Mégsem"
      no-close-on-backdrop
      :ok-disabled="isDisabled || waiting"
      cancel-variant="outline-secondary"
      ok-variant="primary"
      @show="initModal"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <validation-observer ref="rules">
      <b-form
          ref="form"
          @submit.stop.prevent="handleSubmit"
      >
        <b-row>
          <b-col sm="12">
            <b-form-group
                label="Adat"
            >
              <validation-provider
                  v-if="isFormNumber"
                  #default="{ errors }"
                  name="data"
                  rules="required"
                  vid="data"
              >
                <b-form-input
                    v-model="formData.value"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                  v-else-if="isFormFloat"
                  #default="{ errors }"
                  name="data"
                  rules="required"
                  vid="data"
              >
                <b-form-input
                    v-model="formData.value"
                    :state="errors.length > 0 ? false:null"
                    type="number"
                    step="0.01"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                  v-else-if="isSelectInput"
                  #default="{ errors }"
                  name="data"
                  rules="required"
                  vid="data"
              >
                <v-select
                    v-model="formData.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="selectOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                  v-else
                  #default="{ errors }"
                  name="data"
                  rules="required"
                  vid="data"
              >
                <b-form-input
                    v-model="formData.value"
                    :state="errors.length > 0 ? false:null"
                    type="number"
                    step="0.01"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
                label="Dátum"
            >
              <validation-provider
                  #default="{ errors }"
                  name="date"
                  rules="required"
                  vid="date"
              >
                <flat-pickr
                    v-model="formData.date"
                    class="form-control"
                    :config="flatPickRConfig"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal, BFormInput, BFormGroup, BForm, BRow, BCol,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BModal,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      formData: {
        id: null,
        value: '',
        date: '',
      },
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      selectOptions: [],
      waiting: false,
      required,
    }
  },
  props: {
    data: {
      type: Object,
      required: false,
    },
    tabData: {
      type: Object,
      required: false,
    },
  },
  computed: {
    isDisabled() {
      return !this.formData.value.hasOwnProperty('value') && !this.formData.value.length
    },
    isFormNumber() {
      return this.tabData.hasOwnProperty('input') && ['integer'].includes(this.tabData.input.data_type)
    },
    isFormFloat() {
      return this.tabData.hasOwnProperty('input') && ['float'].includes(this.tabData.input.data_type)
    },
    isSelectInput() {
      return this.tabData.hasOwnProperty('input') && ['select'].includes(this.tabData.input.data_type)
    },
  },
  methods: {
    initModal() {
      this.resetForm()
      this.formData.id = this.data.id
      this.formData.value = this.data.value
      this.formData.date = this.data.created_at

      this.selectOptions = []
      let selectInput = false
      const typeName = this.tabData.type_name

      // option-ök összeállítása
      if(typeName && this.tabData.hasOwnProperty('input') && ['select'].includes(this.tabData.input.data_type)) {
        selectInput = true
        if (['cloud', 'nature_of_precipitation', 'ice_cover'].includes(typeName)) {
          this.tabData.input.available_values.forEach((value, index) => {
            if(typeName === 'ice_cover' && index === 0) {
              return
            }

            this.selectOptions.push({ ... {value: index, title: value.label}, ... value })
          })
        } else if (['wind_direction', 'wind_strength'].includes(typeName)) {
          this.tabData.input.available_values.forEach(value => {
            this.selectOptions.push({ ... {value: value.avg, title: value.label}, ... value })
          })
        }
      }

      // kiválasztott érték megállapítása
      if (selectInput && this.selectOptions.length) {
        let pValue = parseInt(this.formData.value)
        if (['wind_direction', 'wind_strength'].includes(typeName)) {
          pValue = parseFloat(this.formData.value)
        }

        if (typeName === 'wind_direction' && pValue === 0) {
          pValue = 360
        }

        if (['wind_direction', 'wind_strength'].includes(typeName)) {
          let max
          this.selectOptions.forEach(option => {
            max = option.max

            if (typeName === 'wind_direction') {
              max = option.min + 45
            }

            if (pValue >= option.min && pValue <= max) { // van egy olyan érték, ahol a max 22.5, nem törtem meg az adatstruktúrát
              this.formData.value = option
            }
          })
        } else {
          this.selectOptions.forEach(option => {
            if (pValue === option.value) {
              this.formData.value = option
            }
          })

          if (this.formData.value === this.data.value && this.selectOptions.length) {
            this.formData.value = this.selectOptions[0]
          }
        }
      }
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.waiting = true

          let fData = this.$helpers.cloneObject(this.formData)

          // select-ek esetén kikell venni a value-t
          if (['select'].includes(this.tabData.input.data_type)) {
            fData.value = fData.value.value
          }

          this.$store.dispatch('updateMeteorologyData', fData).then(() => {
            this.$helpers.showSuccessToast()
            this.$nextTick(() => {
              this.$refs['show-form-modify-meteorology-modal'].toggle('#toggle-btn')
            })
            this.$emit('refresh')
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.rules.setErrors(error.response.data.errors)
            } else if(error.response.status === 500){
                this.$helpers.showErrorToast(error.response.data.message)
            }else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.waiting = false
          })

        }
      })
    },
    handleCancel() {
      this.$refs['show-form-modify-meteorology-modal'].toggle('#toggle-btn')
    },
    resetForm() {
      this.formData.value = ''
    },
  },
}
</script>
